<template>
	<div class="invalid-answer">
		<div class="cont">
				<div class="head">
					<el-button type="primary" @click="addRules">添加筛选规则</el-button>
					<el-button @click="back">返回</el-button>
			</div>
			<div class="body">
				<div class="rules-list" v-if="tableData.length > 0">
						<el-table :data="tableData" border >
							<el-table-column prop="num" label="编号" width="250" align="center"></el-table-column>
							<el-table-column prop="type" label="规则类型" width="100" align="center"></el-table-column>
							<el-table-column prop="describe" label="规则描述"></el-table-column>
							<el-table-column prop="time" label="创建时间" width="200" align="center"></el-table-column>
							<el-table-column label="状态" width="100" align="center">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.status" active-value="1" inactive-value="0" active-color="#0cf" inactive-color="#ddd" @change="changeRuleItemStatus($event,scope.row._id)"></el-switch>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="150" align="center">
								<template slot-scope="scope">
									<el-button @click="editRules(scope.row.showData)"><i class="el-icon-edit-outline"></i>编辑</el-button>
									<el-button @click="deleteRules(scope.row.showData)"><i class="el-icon-delete"></i>删除</el-button>
								</template>
							</el-table-column>
						</el-table>
				</div>
				<noData v-else text="暂时还没有数据哦！"></noData>
			</div>
		</div>
		<!-- 添加筛选规则弹框 -->
		<el-dialog class="filter-rules-pop" :visible.sync="dialogVisible">
			<div slot="title" class="dialog-header">
				<div class="txt">添加无效答卷筛选规则</div>
				<el-tooltip class="item" effect="dark" content="其中省份、城市都根据答題者IP地址获取" placement="right">
					<span class="el-icon-info"></span>
				</el-tooltip>
			</div>
			<div class="dialog-body">
				<div class="set-list">
					<div class="item">
						<div class="label">规则类型：</div>
						<div class="r-cont">
							<el-radio-group v-model="ruleType">
								<el-radio :label="1">省份</el-radio>
								<el-radio :label="2">城市</el-radio>
								<el-radio :label="3">答题时长</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div v-show="ruleType == 1">
						<div class="item">
							<div class="label">规则类型：</div>
							<div class="r-cont">
								<el-radio-group v-model="provinceType">
									<el-radio :label="1">省份位于</el-radio>
									<el-radio :label="2">省份没有位于</el-radio>
								</el-radio-group>
								<div class="bd-box">
									<el-scrollbar>
										<div v-show="provinceType == 1">
											<el-checkbox-group v-model="isProvince">
												<el-checkbox :label="item.label" v-for="item in regionData" :key="item.value"></el-checkbox>
											</el-checkbox-group>
										</div>
										<div v-show="provinceType == 2">
											<el-checkbox-group v-model="notIsProvince">
												<el-checkbox :label="item.label" v-for="item in regionData" :key="item.value"></el-checkbox>
											</el-checkbox-group>
										</div>
									</el-scrollbar>
								</div>
							</div>
						</div>
					</div>
					<div v-show="ruleType == 2">
						<div class="item">
							<div class="label">规则类型：</div>
							<div class="r-cont">
								<el-radio-group v-model="cityType">
									<el-radio :label="1">城市位于</el-radio>
									<el-radio :label="2">城市没有位于</el-radio>
								</el-radio-group>
								<div class="bd-box">
									<el-scrollbar>
										<div class="prov-list" v-show="cityType == 1">
											<div class="prov-item" v-for="item in regionData" :key="item.value">
												<el-checkbox :value="item.children.every(v=>isCity.indexOf(v) > -1)" :label="item.label" @change="changeIsCityParent($event,item)"></el-checkbox>
												<div class="sub-box">
													<el-checkbox :value="isCity.some(v=>v.value == o.value)" :label="o.label" v-for="o in item.children" :key="o.value" @change="changeIsCityChild($event,o)"></el-checkbox>
												</div>
											</div>
										</div>
										<div class="prov-list" v-show="cityType == 2">
											<div class="prov-item" v-for="item in regionData" :key="item.value">
												<el-checkbox :value="item.children.every(v=>notIsCity.indexOf(v) > -1)" :label="item.label" @change="changeNoIsCityParent($event,item)"></el-checkbox>
												<div class="sub-box">
													<el-checkbox :value="notIsCity.some(v=>v.value == o.value)" :label="o.label" v-for="o in item.children" :key="o.value" @change="changeNoIsCityChild($event,o)"></el-checkbox>
												</div>
											</div>
										</div>
									</el-scrollbar>
								</div>
							</div>
						</div>
					</div>
					<div v-show="ruleType == 3">
						<div class="item">
							<div class="label">规则类型：</div>
							<div class="r-cont">
								<el-radio-group v-model="timeType">
									<el-radio :label="1">大于等于</el-radio>
									<el-radio :label="2">小于等于</el-radio>
									<el-radio :label="3">介于</el-radio>
								</el-radio-group>
								<div>
									<div class="time-box" v-show="timeType == 1">
										<el-input type="number" placeholder="请输入时间" min="0" v-model="gtTime"></el-input>
										<el-select placeholder="请选择" v-model="gtUnit">
											<el-option label="秒" value="s"></el-option>
											<el-option label="分" value="m"></el-option>
											<el-option label="时" value="h"></el-option>
										</el-select>
									</div>
									<div class="time-box" v-show="timeType == 2">
										<el-input type="number" placeholder="请输入时间" min="0" v-model="ltTime"></el-input>
										<el-select placeholder="请选择" v-model="ltUnit">
											<el-option label="秒" value="s"></el-option>
											<el-option label="分" value="m"></el-option>
											<el-option label="时" value="h"></el-option>
										</el-select>
									</div>
									<div class="time-box" v-show="timeType == 3">
										<el-input type="number" placeholder="请输入时间" min="0" v-model="betweenTime1" @input="watchMin"></el-input>
										<span class="txt">至</span>
										<el-input type="number" placeholder="请输入时间" min="0" v-model="betweenTime2" @input="watchMax"></el-input>
										<el-select placeholder="请选择" v-model="betweenUnit">
											<el-option label="秒" value="s"></el-option>
											<el-option label="分" value="m"></el-option>
											<el-option label="时" value="h"></el-option>
										</el-select>
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitRule">提交</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { get,post,postJson } from "/src/services/ajax.js";
import noData from "@/components/noData";
import { regionData  } from 'element-china-area-data';
export default {
	components: {
		noData,		
	},
	data(){
		return {
			formId: sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'',
			tableData: [],
			dialogVisible: false,
			regionData,
			ruleType: 1,
			provinceType: 1,
			cityType: 1,
			timeType: 1,
			isProvince: [],
			notIsProvince: [],
			isCity: [],
			notIsCity: [],
			gtTime: '',
			gtUnit: 's',
			ltTime: '',
			ltUnit: 's',
			betweenTime1: '',
			betweenTime2: '',
			betweenUnit: 's',
			id: ''
		}
	},
	mounted(){
		if(this.formId){
			this.getListData(this.formId);
		}else{
			console.log('去登录');
		}
	},
	methods: {
		// 获取数据
		getListData(formId){
			const _this = this;
			get('/formdesign/loadUserFormControl.api',{formId}).then(res => {
				if(res.code == 200){
					if(res.data.list.length > 0){
						res.data.list.forEach(element => {
							let obj = {};
							obj.num = element._id;
							obj.type = element.ruleType == 1?'省份':element.ruleType == 2?'城市':'答题时间';
							obj.time = element.createTime;
							if(element.ruleType == 1){
								if(element.provinceType == 1){
									const provinceStr = element.isProvince.join('、');
									obj.describe = `答题者所在省份必须是：${provinceStr}`;
								}else{
									const provinceStr = element.notIsProvince.join('、');
									obj.describe = `答题者所在省份不能是：${provinceStr}`;
								}
							}else if(element.ruleType == 2){
								if(element.cityType == 1){
									let cityArr = [];
									element.isCity.forEach(v=>{
										if(v.value == '110100'){
											cityArr.push('北京市');
										}else if(v.value == '120100'){
											cityArr.push('天津市');
										}else if(v.value == '310100'){
											cityArr.push('上海市');
										}else if(v.value == '500100'){
											cityArr.push('重庆市区');
										}else if(v.value == '500200'){
											cityArr.push('重庆市县');
										}else{
											cityArr.push(v.label);
										}
									})
									const cityStr = cityArr.join('、');
									obj.describe = `答题者所在城市必须是：${cityStr}`;
								}else{
									let cityArr = [];
									element.notIsCity.forEach(v=>{
										cityArr.push(v.label);
									})
									const cityStr = cityArr.join('、');
									obj.describe = `答题者所在城市不能是：${cityStr}`;
								}
							}else{
								if(element.timeType == 1){
									const unit = element.gtUnit == 's'?'秒':element.gtUnit == 'm'?'分钟':'小时';
									obj.describe = `答题时间不能小于${element.gtTime}${unit}`;
								}else if(element.timeType == 2){
									const unit = element.ltUnit == 's'?'秒':element.ltUnit == 'm'?'分钟':'小时';
									obj.describe = `答题时间不能大于${element.ltTime}${unit}`;
								}else{
									const unit = element.betweenUnit == 's'?'秒':element.betweenUnit == 'm'?'分钟':'小时';
									obj.describe = `答题时间必须介于${element.betweenTime1}${unit}到${element.betweenTime2}${unit}之间`;
								}
							}
							obj.status = element.status;
							obj._id = element._id;
							obj.showData = element;
							_this.tableData.push(obj);
						});
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 切换状态
		changeRuleItemStatus(val,id){
			const _this = this;
			if(val == 1){ // 开启
				post('/formdesign/openUserFormControl.api',{_id: id}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							center: true,
							duration: 500,
							message: '开启成功！'
						});
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}else{ // 关闭
				post('/formdesign/closeUserFormControl.api',{_id: id}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'info',
							duration: 500,
							center: true,
							message: '关闭成功！'
						});
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 添加筛选规则
		addRules(){
			const _this = this;
			_this.ruleType = 1;
			_this.provinceType = 1;
			_this.cityType = 1;
			_this.timeType = 1;
			_this.isProvince = [];
			_this.notIsProvince = [];
			_this.isCity = [];
			_this.notIsCity = [];
			_this.gtTime = '';
			_this.gtUnit = 's';
			_this.ltTime = '';
			_this.ltUnit = 's';
			_this.betweenTime1 = '';
			_this.betweenTime2 = '';
			_this.betweenUnit = 's';
			_this.id = '';
			_this.dialogVisible = true;
		},
		// 编辑规则
		editRules(obj){
			this.ruleType = obj.ruleType;
			this.provinceType = obj.provinceType;
			this.cityType = obj.cityType;
			this.timeType = obj.timeType;
			this.isProvince = obj.isProvince;
			this.notIsProvince = obj.notIsProvince;
			this.isCity = obj.isCity;
			this.notIsCity = obj.notIsCity;
			this.gtTime = obj.gtTime;
			this.gtUnit = obj.gtUnit;
			this.ltTime = obj.ltTime;
			this.ltUnit = obj.ltUnit;
			this.betweenTime1 = obj.betweenTime1;
			this.betweenTime2 = obj.betweenTime2;
			this.betweenUnit = obj.betweenUnit;
			this.id = obj._id;
			this.dialogVisible = true;
		},
		// 删除规则
		deleteRules(obj){
			const _this = this;
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formdesign/deleteUserFormControl.api',{id: obj._id}).then(res => {
					if(res.code == 200){
						_this.$message({
							duration: 500,
							type: 'success',
							message: '删除成功!',
							onClose(){
								_this.tableData = [];
								_this.getListData(_this.formId);
							}
						});
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {

			})
		},
		// 城市位于 父元素选择
		changeIsCityParent(e,item){
			const _this = this;
			item.children.forEach(element => {
				if(_this.isCity.indexOf(element) > -1){
					_this.isCity.splice(_this.isCity.indexOf(element), 1)
				}
				if(e){
					_this.isCity.push(element);
				}
			});
		},
		// 城市位于 子元素选择
		changeIsCityChild(e,o){
			const _this = this;
			if(e){
				_this.isCity.push(o);
			}else{
				_this.isCity.splice(_this.isCity.indexOf(o), 1)
			}
		},
		// 城市没有位于 父元素选择
		changeNoIsCityParent(e,item){
			const _this = this;
			item.children.forEach(element => {
				if(_this.notIsCity.indexOf(element) > -1){
					_this.notIsCity.splice(_this.notIsCity.indexOf(element), 1)
				}
				if(e){
					_this.notIsCity.push(element);
				}
			});
		},
		// 城市没有位于 子元素选择
		changeNoIsCityChild(e,o){
			const _this = this;
			if(e){
				_this.notIsCity.push(o);
			}else{
				_this.notIsCity.splice(_this.notIsCity.indexOf(o), 1)
			}
		},
		// 判断大于
		watchMin(value){
			if(value > this.betweenTime2 && this.betweenTime2){
				this.$message.error(`不能大于${this.betweenTime2}`);
			}
		},
		// 判断小于
		watchMax(value){
			if(value < this.betweenTime1 && this.betweenTime1){
				this.$message.error(`不能小于${this.betweenTime1}`);
			}
		},
		// 提交规则
		submitRule(){
			const _this = this;
			let jsonData = {
				formId: _this.formId,
			}
			jsonData.ruleType = _this.ruleType;

			if(_this.ruleType == 1){
				if(_this.provinceType == 1){
					if(_this.isProvince.length == 0){
						_this.$message.error('请选择省份！');
						return false;
					}
				}else{
					if(_this.notIsProvince.length == 0){
						_this.$message.error('请选择省份！');
						return false;
					}
				}
			}else if(_this.ruleType == 2){
				if(_this.cityType == 1){
					if(_this.isCity.length == 0){
						_this.$message.error('请选择城市！');
						return false;
					}
				}else{
					if(_this.notIsCity.length == 0){
						_this.$message.error('请选择城市！');
						return false;
					}
				}
			}else{
				if(_this.timeType == 1){
					if(_this.gtTime == ''){
						_this.$message.error('请输入时间！');
						return false;
					}
				}else if(_this.timeType == 2){
					if(_this.ltTime == ''){
						_this.$message.error('请输入时间！');
						return false;
					}
				}else{
					if(_this.betweenTime1 == ''){
						_this.$message.error('请输入时间！');
						return false;
					}else if(_this.betweenTime2 == ''){
						_this.$message.error('请输入时间！');
						return false;
					}else if(_this.betweenTime1 > _this.betweenTime2){
						_this.$message.error('介于时间后一个值不能小于前一个值！');
						return false;
					}
				}
			}
			jsonData.ruleType = _this.ruleType;
			jsonData.provinceType = _this.provinceType;
			jsonData.cityType = _this.cityType;
			jsonData.timeType = _this.timeType;
			jsonData.isProvince = _this.isProvince;
			jsonData.notIsProvince = _this.notIsProvince;
			jsonData.isCity = _this.isCity;
			jsonData.notIsCity = _this.notIsCity;
			jsonData.gtTime = _this.gtTime;
			jsonData.gtUnit = _this.gtUnit;
			jsonData.ltTime = _this.ltTime;
			jsonData.ltUnit = _this.ltUnit;
			jsonData.betweenTime1 = _this.betweenTime1;
			jsonData.betweenTime2 = _this.betweenTime2;
			jsonData.betweenUnit = _this.betweenUnit;
			if(_this.id){
				jsonData._id = _this.id;
			}
			postJson('/formdesign/updateUserFormControl.api', jsonData).then(res => {
				if(res.code == 200){
					_this.$message({
						type: 'success',
						duration: 1000,
						message: '保存成功！',
						onClose(){
							_this.dialogVisible = false;
							_this.tableData = [];
							_this.getListData(_this.formId);
						}
					});
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 返回
		back(){
			this.$router.go(-1);
		}
	}
};
</script>
<style lang="less" scoped>
	.invalid-answer{
		width: 100%;
		height: 100%;
		padding: 20px;
		.cont{
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			padding: 30px;
			border: 1px solid #eee;
			background-color: #fff;
			.head{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.el-button{
					padding: 10px 15px;
				}
			}
			.body{
				flex: 1;
				.rules-list{
					margin: 20px 0 0;
					::v-deep .el-table{
						thead{
							tr{
								th{
									background-color: #fafafa;
									color: #333;
								}
							}
						}
						.el-button{
							padding: 0;
							border: none;
							background-color: none;
							i{
								margin: 0 3px 0 0;
							}
						}
					}
				}
			}
		}
		.filter-rules-pop{
			::v-deep .el-dialog{
				width: 600px;
				.el-dialog__header{
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 54px;
					padding: 0 20px;
					border-bottom: 1px solid #eee;
					background-color: #F8F8F8;
					.dialog-header{
						display: flex;
						align-items: center;
						font-size: 16px;
						.el-icon-info{
							margin: 0 0 0 5px;
							font-size: 18px;
							color: #bbb;
							cursor: pointer;
						}
					}
				}
				.el-dialog__body{
					height: 379px;
					padding: 30px;
					.set-list{
						.item{
							display: flex;
							margin: 0 0 10px;
							.label{
								line-height: 30px;
							}
							.r-cont{
								flex: 1;
								.el-radio-group{
									display: flex;
									height: 30px;
									align-items: center;
									.el-radio__input.is-checked+.el-radio__label{
										color: #606266;
									}
								}
								.bd-box{
									height: 260px;
									border: 1px solid #e5e5e5;
									margin: 10px 0 0;
									.el-scrollbar{
										width: 100%;
										height: 100%;
										.el-scrollbar__wrap{
											padding: 0 10px;
											overflow-x: hidden;
											.prov-list{
												padding: 10px 0 0;
												.prov-item{
													.el-checkbox{
														font-weight: bold;
													}
													.sub-box{
														padding: 10px 10px 0;
														border: 1px solid #ddd;
														background-color: #f8f8f8;
														margin: 5px 0 15px;
														.el-checkbox{
															min-width: calc(33.33% - 30px);
															margin: 0 30px 10px 0;
															font-weight: normal;
															.el-checkbox__label{
																color: #666;
															}
														}
													}
												}
											}
											.el-checkbox-group{
												padding: 10px 0 0;
												.el-checkbox{
													width: 33.33%;
													margin: 0 0 10px;
												}
											}
											.el-checkbox__input.is-checked+.el-checkbox__label{
												color: #606266;
											}
										}
									}
								}
								.time-box{
									display: flex;
									align-items: center;
									padding: 5px 0 0;
									.el-input__inner{
										height: 30px;
										line-height: 30px;
										padding: 0 10px;
										border-radius: 2px;
									}
									.el-input__icon{
										line-height: 30px;
									}
									.el-input{
										width: 100px;
										margin: 0 10px 0 0;
									}
									.txt{
										margin: 0 10px 0 0;
									}
								}
							}
						}
					}
				}
				.el-dialog__footer{
					.dialog-footer{
						.el-button{
							padding: 10px 20px;
						}
					}
				}
			}
		}
	}
</style>